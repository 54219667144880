import { t } from '@zupr/i18n'
import classnames from 'classnames'
import getConfig from 'next/config'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import ExternalLink from '../router/link-external'

import AreaContext from '../../context/domain'

import CopyToClipboard from './copy-to-clipboard'
import Dropdown from './dropdown'

import { ReactComponent as Facebook } from '../../../svg/facebook.svg'
import { ReactComponent as Messenger } from '../../../svg/messenger.svg'
import { ReactComponent as Twitter } from '../../../svg/twitter.svg'
import { ReactComponent as Whatsapp } from '../../../svg/whatsapp.svg'

import { ReactComponent as Email } from '../../../svg/email.svg'
import { ReactComponent as LinkIcon } from '../../../svg/link.svg'
import { ReactComponent as ShareIcon } from '../../../svg/share-outlined.svg'

import RouteContext from '../../context/route'
import Tooltip from './tooltip'

import '../../../scss/react/components/action-links.scss'

const { publicRuntimeConfig } = getConfig()

export const Socials = ({ url }) => {
    const [isTouchDevice, setTouchDevice] = useState(false)

    const { host } = useContext(AreaContext)
    const { trackEvent } = useContext(RouteContext)

    useEffect(() => {
        setTouchDevice(!!('ontouchstart' in document.documentElement))
    }, [])

    // add shopping area host
    if (!url?.startsWith('https://')) {
        url = `https://${host}${url || ''}`
    }

    const link = encodeURIComponent(url)

    return (
        <React.Fragment>
            <ExternalLink
                onClick={() => {
                    trackEvent({
                        action: 'clickShareFacebook',
                    })
                }}
                className="btn-social social-facebook"
                href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
            >
                <Facebook />
            </ExternalLink>
            <ExternalLink
                onClick={() => {
                    trackEvent({
                        action: 'clickShareTwitter',
                    })
                }}
                className="btn-social social-twitter"
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    url
                )}`}
            >
                <Twitter />
            </ExternalLink>
            <ExternalLink
                onClick={() => {
                    trackEvent({
                        action: 'clickShareWhatsapp',
                    })
                }}
                className="btn-social social-whatsapp"
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                    url
                )}`}
            >
                <Whatsapp />
            </ExternalLink>
            {isTouchDevice && (
                <ExternalLink
                    onClick={() => {
                        trackEvent({
                            action: 'clickShareMessenger',
                        })
                    }}
                    className="btn-social social-messenger"
                    href={`fb-messenger://share/?link=${link}&app_id=${publicRuntimeConfig.NEXT_PUBLIC_FB_ID}`}
                >
                    <Messenger />
                </ExternalLink>
            )}
            <ExternalLink
                onClick={() => {
                    trackEvent({
                        action: 'clickShareMail',
                    })
                }}
                className="btn-social social-mail"
                href={`mailto:?body=${encodeURIComponent(url)}`}
            >
                <Email />
            </ExternalLink>
            <CopyToClipboard text={url} className="btn-social social-mail">
                <LinkIcon />
            </CopyToClipboard>
        </React.Fragment>
    )
}

interface NativeShareProps {
    url: string
    className?: string
}

const NativeShare = ({ className, url }: NativeShareProps) => {
    const { host } = useContext(AreaContext)
    const { trackEvent } = useContext(RouteContext)

    // add shopping area host
    if (!url?.startsWith('https://')) {
        url = `https://${host}${url || ''}`
    }

    const handleShare = useCallback(() => {
        trackEvent({
            action: 'clickShareNative',
        })
        navigator.share({
            url,
        })
    }, [trackEvent, url])

    return (
        <button
            className={classnames(className || 'btn btn-small')}
            onClick={handleShare}
        >
            <ShareIcon />
        </button>
    )
}

interface Props {
    url: string
    className?: string
}

const Share = ({ url, className }: Props) => {
    const [open, setOpen] = useState(false)
    const [native, setNative] = useState(false)

    // set native true in browser context
    // handles server side rendering that does not have a concept of navigator
    useEffect(() => {
        if (navigator.share && navigator.canShare({ url })) {
            setNative(true)
        }
    }, [url])

    const handleClose = useCallback(() => setOpen(false), [])
    const handleOpen = useCallback(() => setOpen(true), [])

    if (native) {
        return (
            <Tooltip label={t('Share')}>
                <NativeShare url={url} className={className} />
            </Tooltip>
        )
    }

    return (
        <Dropdown open={open} className="share-on-social" onClose={handleClose}>
            <span>
                <Tooltip label={t('Share')}>
                    <button
                        className={classnames(className || 'btn btn-small')}
                        aria-label={t('Share')}
                        onClick={handleOpen}
                    >
                        <ShareIcon />
                    </button>
                </Tooltip>
            </span>
            <Socials url={url} />
        </Dropdown>
    )
}

export default Share
