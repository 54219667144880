import React, { useState } from 'react'
import classnames from 'classnames'
import CopyToClipboard from 'react-copy-to-clipboard'

import Tooltip from './tooltip'

const CopyButton = ({ text, className, children }) => {
    const [copied, setCopied] = useState(false)

    const onCopy = () => {
        setCopied(true)
        setTimeout(() => setCopied(false), 1500)
    }

    return (
        <Tooltip label={copied ? 'Copied to clipboard' : 'Copy to clipboard'}>
            <CopyToClipboard text={text} onCopy={onCopy}>
                <button className={classnames(className, { copied })}>
                    {children}
                </button>
            </CopyToClipboard>
        </Tooltip>
    )
}

export default CopyButton
